import * as yup from 'yup';

// checks if there is at least one uppercase character
function containsUppercase(
  this: yup.StringSchema,
  message: string = 'field must contain at least 1 uppercase character',
) {
  return this.test('containsUppercase', message, value => {
    if (value) {
      return /\p{Lu}/u.test(value);
    }
    return false;
  });
}

// checks if there is at least one lowercase character
function containsLowercase(
  this: yup.StringSchema,
  message: string = 'field must contain at least 1 lowercase character',
) {
  return this.test('containsLowercase', message, value => {
    if (value) {
      return /\p{Ll}/u.test(value);
    }
    return false;
  });
}

// checks if there is at least one digit (number)
function containsDigit(this: yup.StringSchema, message: string = 'field must contain at least 1 number') {
  return this.test('containsDigit', message, value => {
    if (value) {
      return /\p{Nd}/u.test(value);
    }
    return false;
  });
}

// checks if there is at least one special character
const containsSpecialCharacter = function (
  this: yup.StringSchema,
  message: string = 'field must contain at least 1 special character',
) {
  return this.test('containsSpecialCharacter', message, value => {
    if (value) {
      return /["?!№@#$%^&*()\-_=+{};:,<.>]/.test(value);
    }
    return false;
  });
};

// checks if a field matches the value of another field
function matchesField(this: yup.StringSchema, fieldToMatch: string, message: string) {
  const errorMessage = message || `field must match to ${fieldToMatch}`;
  return this.test('matchesField', errorMessage, function (value) {
    const { parent } = this;
    if (value && parent) {
      return value === parent[fieldToMatch];
    }
    return false;
  });
}

// checks if a number is between a specific minimum and maximum value.
const minMax = (min: number, max: number, message: string) => {
  const errorMessage = message ?? `field must be between ${min} and ${max}`;

  return yup.string().test('minMax', errorMessage, value => {
    // If the value is undefined, the validation passes (useful if the field is optional).
    if (value === undefined) {
      return true;
    }

    // Check if the length of the value is within the specified range.
    const { length } = value.trim();
    return length >= min && length <= max;
  });
};

yup.addMethod<yup.StringSchema>(yup.string, 'containsLowercase', containsLowercase);
yup.addMethod<yup.StringSchema>(yup.string, 'containsDigit', containsDigit);
yup.addMethod<yup.StringSchema>(yup.string, 'containsSpecialCharacter', containsSpecialCharacter);
yup.addMethod<yup.StringSchema>(yup.string, 'containsUppercase', containsUppercase);
yup.addMethod<yup.StringSchema>(yup.string, 'matchesField', matchesField);
yup.addMethod<yup.StringSchema>(yup.string, 'minMax', minMax);

declare module 'yup' {
  interface StringSchema {
    containsLowercase(message?: string): StringSchema;
    containsDigit(message?: string): StringSchema;
    containsSpecialCharacter(message?: string): StringSchema;
    containsUppercase(message?: string): StringSchema;
    matchesField(fieldToMatch: string, message?: string): StringSchema;
    minMax(min: number, max: number, message?: string): StringSchema;
  }
}
